import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { productData } from "../../data"; // Import the product data
import { GetColorName } from "hex-color-to-color-name"; // Import the library to convert hex to color name
import { toast } from "react-toastify"; // Import the Cart context
import { useCart } from "../../contexts/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the product ID from the URL
  const product = productData.data.find((prod) => prod.id === parseInt(id)); // Find the product by ID
  const { cartItems, addToCart } = useCart(); // Access the addToCart function from CartContext

  const [mainMedia, setMainMedia] = useState(product?.imagePath[0]); // Set the main image
  const [isVideo, setIsVideo] = useState(false); // State to determine if it's an image or a video

  if (!product) {
    return <div>Product not found</div>; // Handle case if product is not found
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  // // const handleImageClick = (image) => {
  // //   setMainImage(image);
  // // };
  // const handleImageClick = (media) => {
  //   if (media.endsWith(".mp4")) {
  //     setMainMedia(media); // Set the video as the main media
  //     setIsVideo(true); // Set the flag for video
  //   } else {
  //     setMainMedia(media); // Set the image as the main media
  //     setIsVideo(false); // Set the flag for image
  //   }
  // };
  // Function to handle image/video click
  const handleImageClick = (media) => {
    if (media.endsWith(".mp4")) {
      setMainMedia(media);
      setIsVideo(true);
    } else {
      setMainMedia(media);
      setIsVideo(false);
    }
  };

  const handleBuyNow = () => {
    const productData = {
      id: product.id, // Product ID
      title: product.title, // Product title
      price: product.price, // Product price
      sku: product.sku, // Product SKU
      color: product.color || "", // Product color (if applicable)
      size: product.size || "", // Product size (if applicable)
      material: product.material || "", // Product material (if applicable)
      imagePath: product.imagePath[0], // Main image
      quantity: 1, // Default quantity
      // Add other details as necessary, like customization if applicable
    };

    navigate("/checkout", {
      state: {
        productDetails: [productData], // Passing product details as an array
        amount: product.price, // Total price (or calculated if multiple products)
      },
    });
  };

  const handleAddToCart = () => {
    const newCartItem = {
      id: product.id,
      title: product.title,
      price: product.price,
      sku: product.sku, // Product SKU
      color: product.color || "",
      size: product.size || "",
      imagePath: product.imagePath[0], // Main image
      quantity: 1,
    };
    // Check if the item is already in the cart
    const isAlreadyInCart = cartItems.some(
      (item) => item.id === newCartItem.id
    );

    if (!isAlreadyInCart) {
      addToCart(newCartItem); // Use the addToCart function from context
      toast.success("Added to Bag", {
        autoClose: 1000,
        position: "bottom-right",
      });
    } else {
      // Optionally show a message indicating it's already in the cart
      toast.info("Item is already in your bag", {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  return (
    <section className="product-detail-page">
      <div className="py-5 product-detail-page-block">
        <div className="our-container">
          <div className="inner-container px-5 mx-auto">
            <button
              onClick={handleBackClick}
              className="back-btn-per-page mb-3"
            >
              <MdArrowBackIos className="back-icon-per-page" />
              Back
            </button>
            <div className="row product-detail-page-block-row">
              <div className="col-md-6 mb-3">
                <div className="row gap-3 px-3 mobile-direction">
                  <div className="col-md-2 d-flex align-items-start justify-content-center product-detail-page-img-grp">
                    <div className="row gap-3 product-detail-page-img-group">
                      {/* {product.imagePath.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Option ${index + 1}`}
                          onClick={() => handleImageClick(image)}
                          className={`thumbnail ${
                            mainImage === image ? "active" : ""
                          }`}
                        />
                      ))} */}
                      {/* {product.imagePath.map((media, index) => (
                        <img
                          key={index}
                          src={
                            media.endsWith(".mp4")
                              ? "path/to/default-thumbnail.jpg" // Default thumbnail for video
                              : media
                          }
                          alt={`Option ${index + 1}`}
                          onClick={() => handleImageClick(media)}
                          className={`thumbnail ${
                            mainMedia === media ? "active" : ""
                          }`}
                        />
                      ))} */}
                      {/* {product.imagePath.map((media, index) =>
                        media.endsWith("thumbnail.jpg") ? (
                          <img
                            key={index}
                            src="/path/to/your/uploaded/image.png" // Replace with the correct path of the uploaded image
                            alt={`Thumbnail ${index + 1}`}
                            onClick={() => handleImageClick(media)}
                            className={`thumbnail ${
                              mainMedia === media ? "active" : ""
                            }`}
                          />
                        ) : (
                          <img
                            key={index}
                            src={media}
                            alt={`Option ${index + 1}`}
                            onClick={() => handleImageClick(media)}
                            className={`thumbnail ${
                              mainMedia === media ? "active" : ""
                            }`}
                          />
                        )
                      )} */}
                      {/* {product.imagePath.map((media, index) =>
                        media.endsWith(".mp4") ? (
                          <div
                            key={index}
                            className={`d-flex align-items-center justify-content-center thumbnail video-icon-container ${
                              mainMedia === media ? "active" : ""
                            }`}
                            onClick={() => handleImageClick(media)}
                          >
                            <FontAwesomeIcon icon={faVideo} size="2x" />
                          </div>
                        ) : (
                          <img
                            key={index}
                            src={media}
                            alt={`Option ${index + 1}`}
                            onClick={() => handleImageClick(media)}
                            className={`thumbnail ${
                              mainMedia === media ? "active" : ""
                            }`}
                          />
                        )
                      )} */}
                      {product.imagePath.map((media, index) =>
                        media.endsWith(".mp4") ? (
                          <span
                            key={index}
                            className={`px-0 thumbnail video-icon-container ${
                              mainMedia === media ? "active" : ""
                            }`}
                            onClick={() => handleImageClick(media)}
                            style={{ width: "auto" }}
                          >
                            {/* Use a video thumbnail */}
                            <img
                              src={product.imageThumbnail} // Show video thumbnail
                              alt={`Thumbnail ${index + 1}`}
                              className="video-thumbnail"
                            />
                            {/* <FontAwesomeIcon icon={faVideo} size="2x" className="video-icon" /> */}
                          </span>
                        ) : (
                          <img
                            key={index}
                            src={media} // Image thumbnail
                            alt={`Option ${index + 1}`}
                            onClick={() => handleImageClick(media)}
                            className={`thumbnail ${
                              mainMedia === media ? "active" : ""
                            }`}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="col-md-9 d-flex align-items-center justify-content-center product-detail-page-main-img">
                    {/* <img src={mainImage} alt={product.title} className="main-image" /> */}
                    {/* {isVideo ? (
                      <video
                        src={mainMedia}
                        controls
                        autoPlay
                        muted
                        className="main-video"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        src={mainMedia}
                        alt={product.title}
                        className="main-image"
                      />
                    )} */}
                    {isVideo ? (
                      <video
                        src={mainMedia}
                        controls
                        autoPlay
                        muted
                        className="main-video"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        src={mainMedia}
                        alt={product.title}
                        className="main-image"
                      />
                    )}
                    {/* <Link
                      to={"https://www.instagram.com/karvaccessories.official/"}
                      target="_blank"
                      className="img-show-icon"
                    >
                      <FaSquareInstagram className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-detail-page-details">
                  <h1 className="fs-4 fw-medium mb-3 text-capitalize">
                    {product.title}
                  </h1>
                  <div className="d-flex align-items-center mb-3 gap-2 product-price">
                    <FaIndianRupeeSign className="fs-6 fw-medium mb-0 price-icon" />
                    <div className="h3 fs-5 fw-medium mb-0">
                      <del>{product.price}</del>
                    </div>
                    <div className="h3 fs-5 fw-medium mb-0">
                    {product.discountPrice}
                    </div>
                  </div>

                  {product.color && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="colors"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Color
                      </label>
                      <div>
                        <span
                          style={{
                            backgroundColor: `${product.color}`,
                            width: "30px",
                            height: "30px",
                            display: "inline-block",
                            border: "1px solid #ccc",
                            borderRadius: "50%",
                          }}
                        ></span>
                      </div>
                    </div>
                  )}

                  {product.size && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="size"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Size
                      </label>
                      <div>
                        <span
                          className="text-capitalize"
                          style={{ fontFamily: "CalibreRegular" }}
                        >
                          {product.size}
                        </span>
                      </div>
                    </div>
                  )}

                  {product.material && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="material"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Material
                      </label>
                      <div>
                        <span
                          className="text-capitalize"
                          style={{ fontFamily: "CalibreRegular" }}
                        >
                          {product.material}
                        </span>
                      </div>
                    </div>
                  )}

                  {product.fabric && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="fabric"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Fabric
                      </label>
                      <div>
                        <span
                          className="text-capitalize"
                          style={{ fontFamily: "CalibreRegular" }}
                        >
                          {product.fabric}
                        </span>
                      </div>
                    </div>
                  )}

                  {product.design && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="design"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Design
                      </label>
                      <div>
                        <span
                          className="text-capitalize"
                          style={{ fontFamily: "CalibreRegular" }}
                        >
                          {product.design}
                        </span>
                      </div>
                    </div>
                  )}

                  {product.description && (
                    <div className="mb-3 d-flex justify-content-center flex-column product-detail-page-form-group">
                      <label
                        htmlFor="design"
                        className="fs-6 text-capitalize fw-medium"
                      >
                        Description
                      </label>
                      <div>
                        <p
                          className="product-description"
                          style={{ fontFamily: "CalibreRegular" }}
                        >
                          {product.description}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row gap-2 mx-0">
                    <button
                      className="col fs-6 fw-medium text-uppercase product-detail-page-form-button"
                      onClick={handleBuyNow}
                    >
                      Buy Now
                    </button>
                    <button
                      className="col fs-6 fw-medium text-uppercase product-detail-page-form-button"
                      onClick={handleAddToCart}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
