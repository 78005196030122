import { useEffect, useState } from "react";
import { exhibitionVideos, productData, sliderData } from "../../data";
import Product from "../../components/product";
import SlickCarousel from "../../components/SlickCarousel";
import Testimonial from "../../components/Testimonial";
import MetaDescTag from "../../components/MetaDescTag";

const Products = () => {
  // website design and developed info
  const websiteDevelopedBy = localStorage.setItem(
    "websiteDevelopedBy",
    process.env.REACT_APP_OWNER_FOR_DEVELOPMENT
  );
  
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(6);

  useEffect(() => {
    setProducts(productData.data);
  }, []);

  // Function to load more products
  const loadMoreProducts = () => {
    setVisibleProducts((prevValue) => prevValue + 6); // Load 6 more products on click
  };
  return (
    <>
      <MetaDescTag
        title={`Ethnic Wear | Products | EthnicINDI`}
        description={`Welcome to EthnicINDI, where culture meets elegance. 'Ethnic' reflects global culture, and 'INDI' honors India. Our peacock-inspired designs celebrate beauty and heritage.`}
      />
      <section className="product-page">
        <div className="pb-5 product-page-block">
          <div className="main-banner-block mb-5 overflow-hidden">
            <div className="p-0">
              <SlickCarousel sliderData={sliderData} />
            </div>
          </div>
          <div className="our-container">
            {/* <div className="inner-container px-5 mx-auto mb-5">
              <h1 className="fs-4 text-center mb-5">
                Exclusive Women’s Ethnic Wear
              </h1>
              <div className="product-page-body">
                <div className="d-grid gap-3 product-page-row">
                  {products.map((product) => (
                    <Product key={product.id} product={product} />
                  ))}
                </div>
              </div>
            </div> */}
            <div className="inner-container px-5 mx-auto mb-5">
            <h1 className="fs-4 text-center mb-5">
                Exclusive Women’s Ethnic Wear
              </h1>
              <div className="product-page-body">
                <div className="d-grid gap-3 product-page-row">
                  {products.slice(0, visibleProducts).map((product) => (
                    <Product key={product.id} product={product} />
                  ))}
                </div>
              </div>
              {visibleProducts < products.length && (
                  <div className="d-grid gap-2 col-2 mx-auto mt-4 mb-5">
                    <button
                      onClick={loadMoreProducts}
                      className="load-more-btn"
                    >
                      Load More...
                    </button>
                  </div>
                )}
            </div>
            <div className="inner-container px-5 mx-auto">
              <div className="exhibition-body">
                <div className="h3 text-center mb-5"><h2 className="d-inline-block fs-3">EthnicINDI</h2> Expo Tour</div>
                <div className="exhibition-row row">
                  {exhibitionVideos.map((item, id) => {
                    return (
                      <div
                        className="col-12 col-sm-4 col-md-4 col-lg-4 exhibition-row-blk"
                        key={id}
                      >
                        <video
                          src={item.urlVideo}
                          // controls
                          autoPlay
                          muted
                          loop
                          className="main-video"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-container px-5 mx-auto">
                <div className="testimonial-body">
                  <Testimonial />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
