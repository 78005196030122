export const productData = {
  status: "Successful",
  code: 200,
  data: [
    {
      id: 1,
      title: "Indowestern Jumpsuit",
      material: "Sequin & Thread work on the jacket",
      price: 8599,
      discountPrice: 6499,
      color: "#e094a6",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS01",
      description:
        "Fusion of comfort & fashion making it an ideal choice  for any special occasion",
      image: "../images/models/img-1-01.jpg",
      imagePath: [
        "../images/models/img-1-01.jpg",
        "../images/models/img-1-02.jpg",
        "../images/models/img-1-03.jpg",
      ],
    },
    {
      id: 2,
      title: "Yellow Party wear crop top & Dhoti set",
      material:
        "Position print full sleeves long jacket with satin dhoti & crop top",
      price: 9999,
      discountPrice: 7500,
      color: "#f19c42",
      fabric: "Dhoti  - Satin, Dhoti  - Satin, Jacket - Georgette",
      size: "Large",
      design: "Stone, embroidery, sequins & mirror work",
      sku: "MS02",
      description:
        "A crop top & dhoti set with jacket suitable for various wedding functions & festivals",
      image: "../images/models/img-2-01.jpg",
      imagePath: [
        "../images/models/img-2-01.jpg",
        "../images/models/img-2-02.jpg",
        "../images/models/img-2-03.jpg",
        "../images/models/img-2-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-2-04.png",
    },
    {
      id: 3,
      title: "Yellow Georgette Sider Jumpsuit",
      material: "Abala Material",
      price: 9000,
      discountPrice: 7500,
      color: "#fecb5c",
      fabric: "Georgette",
      size: "Large",
      design: "Embroidery, sequins & Mirror work",
      sku: "MS03",
      description:
        "Embellished with premium fabric, embroidery, sequins & mirror work",
      image: "../images/models/img-3-01.jpg",
      imagePath: [
        "../images/models/img-3-01.jpg",
        "../images/models/img-3-02.jpg",
        "../images/models/img-3-03.jpg",
        "../images/models/img-3-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-3-04.png",
    },
    {
      id: 4,
      title: "Floor Length Gown",
      material: "",
      price: 13999,
      discountPrice: 11999,
      color: "#454663",
      fabric: "Georgette",
      size: "Large",
      design: "Computer Embroidery work",
      sku: "MS04",
      description: "Georgette floor length gown for special occassions",
      image: "../images/models/img-4-01.jpg",
      imagePath: [
        "../images/models/img-4-01.jpg",
        "../images/models/img-4-02.jpg",
        "../images/models/img-4-03.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-4-03.png",
    },
    {
      id: 5,
      title: "white woven gharara set",
      material: "Woven Thread work on top & Gharara",
      price: 8999,
      discountPrice: 5500,
      color: "#ffffff",
      fabric: "Chinnon",
      size: "Large",
      Design: "",
      sku: "MS05",
      description:
        "A chic and modern ensemble, a white woven crop top gharara set",
      image: "../images/models/img-5-01.jpg",
      imagePath: [
        "../images/models/img-5-01.jpg",
        "../images/models/img-5-02.jpg",
      ],
    },
    {
      id: 6,
      title: "Ready sider jumpsuit with floral jacket",
      material: "",
      price: 12000,
      discountPrice: 8500,
      color: "#034284",
      fabric: "Crepe",
      size: "Large",
      design: "Mirror & Stone work",
      sku: "MS06",
      description:
        "Modern jumpsuit for your cocktail reception, Engagement party & many more",
      image: "../images/models/img-6-01.jpg",
      imagePath: [
        "../images/models/img-6-01.jpg",
        "../images/models/img-6-02.jpg",
        "../images/models/img-6-03.jpg",
        "../images/models/img-6-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-6-04.png",
    },
    {
      id: 7,
      title: "A - Line Crepe straight kurti set",
      material:
        "Multi colored lace work around kurti & pant bodies . Diamond work on kurti",
      price: 8000,
      discountPrice: 6500,
      color: "#e85a7e",
      fabric: "Silk Crepe",
      size: "Large",
      design: "",
      sku: "MS07",
      description:
        "A - Line kurti crafted from luxurious silk crepe, offering both sophistication & comfort",
      image: "../images/models/img-7-01.jpg",
      imagePath: [
        "../images/models/img-7-01.jpg",
        "../images/models/img-7-02.jpg",
        "../images/models/img-7-03.jpg",
      ],
    },
    {
      id: 8,
      title: "Ethnic thread work co-ord set",
      material: "Thread work & mirror work",
      price: 9999,
      discountPrice: 6500,
      color: "#63b5d2",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS08",
      description:
        "An elegant and timeless ensemble, an ethnic thread work co-ord set is perfect for various occasions",
      image: "../images/models/img-8-01.jpg",
      imagePath: [
        "../images/models/img-8-01.jpg",
        "../images/models/img-8-02.jpg",
        "../images/models/img-8-03.jpg",
      ],
    },
    {
      id: 9,
      title: "V Neck crossfit fully diamond work blouse & palazzo",
      material: "Thread work & mirror work",
      price: 8999,
      discountPrice: 5500,
      color: "#dad3c6",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS09",
      description:
        "A bold and glamorous ensemble, this V-neck crossfit fully diamond work blouse and palazzo set",
      image: "../images/models/img-9-01.jpg",
      imagePath: [
        "../images/models/img-9-01.jpg",
        "../images/models/img-9-02.jpg",
        "../images/models/img-9-03.jpg",
        "../images/models/img-9-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-9-04.png",
    },
    {
      id: 10,
      title: "Yellow Embroidered Palazzo set",
      material: "wide leg palazzo with work on jacket sleeves",
      price: 7999,
      discountPrice: 6999,
      color: "#e58150",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS010",
      description:
        "A vibrant and elegant ensemble, this yellow embroidered palazzo set is perfect for various occasions",
      image: "../images/models/img-10-01.jpg",
      imagePath: [
        "../images/models/img-10-01.jpg",
        "../images/models/img-10-02.jpg",
      ],
    },
    {
      id: 11,
      title: "Multi color heavy embroidered brocade georgette lehenga set",
      material: "",
      price: 15999,
      discountPrice: 12699,
      color: "#b4aa9a",
      fabric: "Georgette",
      size: "Large",
      design: "computer print heavy embroidery",
      sku: "MS011",
      description:
        "Elegance redefined with our multi colourheavy embroidered brocade georgette lehenga set",
      image: "../images/models/img-11-01.jpg",
      imagePath: [
        "../images/models/img-11-01.jpg",
        "../images/models/img-11-02.jpg",
        "../images/models/img-11-03.jpg",
      ],
    },
    {
      id: 12,
      title: "orange bandhani embroidered & spaghetti style lehenga set",
      material: "",
      price: 11099,
      discountPrice: 8999,
      color: "#fe5c1b",
      fabric: "Georgette",
      size: "Large",
      design: "position print lehenga set",
      sku: "MS012",
      description:
        "Elevate your ethnic wardrobe with our orange bandhani embroidered lehenga set",
      image: "../images/models/img-12-01.jpg",
      imagePath: [
        "../images/models/img-12-01.jpg",
        "../images/models/img-12-02.jpg",
        "../images/models/img-12-03.jpg",
      ],
    },
    {
      id: 13,
      title: "Mint green georgette V neck lehenga set",
      material: "",
      price: 7999,
      discountPrice: 4999,
      color: "#459187",
      fabric: "Georgette",
      size: "Large",
      design: "position print & mirror",
      sku: "MS013",
      description:
        "Crafted from premium quality georgette fabric, this lehenga set promises comfort & sophistication",
      image: "../images/models/img-13-01.jpg",
      imagePath: [
        "../images/models/img-13-01.jpg",
        "../images/models/img-13-02.jpg",
        "../images/models/img-13-03.jpg",
      ],
    },
    {
      id: 14,
      title: "floor length, round neckline & full sleeves gown",
      material: "georgette print gown with embroidered belt",
      price: 9999,
      discountPrice: 8500,
      color: "#f68c7b",
      fabric: "Georgette",
      size: "Large",
      design: "sequin & mirror work with heavy embroidered border",
      sku: "MS014",
      description:
        "It strike a perfect balance between modesty & modernity, enhancing the overall appeal of the gown",
      image: "../images/models/img-14-01.jpg",
      imagePath: [
        "../images/models/img-14-01.jpg",
        "../images/models/img-14-02.jpg",
        "../images/models/img-14-03.jpg",
      ],
    },
    {
      id: 15,
      title:
        "Elegant green georgette lehenga choli set with handmade blouse & dupatta",
      material: "",
      price: 13999,
      discountPrice: 11999,
      color: "#333f1a",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS015",
      description:
        "The lehenga in a vibrant shade of green, exudes vibrancy & charm",
      image: "../images/models/img-15-01.jpg",
      imagePath: [
        "../images/models/img-15-01.jpg",
        "../images/models/img-15-02.jpg",
        "../images/models/img-15-03.jpg",
      ],
    },
    {
      id: 16,
      title: "sea green lehenga choli",
      material:
        "fully handmade choli/blouse & lightweight lehenga with sequence & mirror work",
      price: 12999,
      discountPrice: 8999,
      color: "#75a1a8",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS016",
      description:
        "This lehenga appropriate for any event & wonderful blend of classic elegance & modern flair",
      image: "../images/models/img-16-01.jpg",
      imagePath: [
        "../images/models/img-16-01.jpg",
        "../images/models/img-16-02.jpg",
        "../images/models/img-16-03.jpg",
      ],
    },
    {
      id: 17,
      title: "Jacquard weave lehenga & handmade choli",
      material: "jacquard print lehenga & handmade choli",
      price: 18999,
      discountPrice: 14499,
      color: "#a50133",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS017",
      description:
        "Experience the epitome of luxury & grace in our weaving jacquard lehenga choli & handmade blouse",
      image: "../images/models/img-17-01.jpg",
      imagePath: [
        "../images/models/img-17-01.jpg",
        "../images/models/img-17-02.jpg",
        "../images/models/img-17-03.jpg",
      ],
    },
    {
      id: 18,
      title: "Net lehenga paired with intricately crafted choli",
      material: "net tikali & handmade diamond blouse",
      price: 12999,
      discountPrice: 9999,
      color: "#1e5c44",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS018",
      description:
        "Envelop yourself in ethereal beauty with our ethnicINDI creation in this wedding season such as engagement, festivals, & ceremonies",
      image: "../images/models/img-18-01.jpg",
      imagePath: [
        "../images/models/img-18-01.jpg",
        "../images/models/img-18-02.jpg",
        "../images/models/img-18-03.jpg",
      ],
    },
    {
      id: 19,
      title:
        "Ghagra choli adorned in exquisite tikali work with a handmade blouse",
      material: "georgette handwork lehenga choli",
      price: 9099,
      discountPrice: 7700,
      color: "#870658",
      fabric: "Georgette",
      size: "Large",
      design: "sequin mirror work & tikali work",
      sku: "MS019",
      description: "Perfect blend of class & sass with our ghagra choli",
      image: "../images/models/img-19-01.jpg",
      imagePath: [
        "../images/models/img-19-01.jpg",
        "../images/models/img-19-02.jpg",
      ],
    },
    {
      id: 20,
      title: "Classy indowestern full length gown",
      material: "georgette floor length gown & long shrug with detachable belt",
      price: 12000,
      discountPrice: 8500,
      color: "#200b19",
      fabric: "Georgette",
      size: "Large",
      design: "stone, embroidery & mirror work",
      sku: "MS020",
      description:
        "The fusion of full length gown, a graceful long shrug & detachable belt",
      image: "../images/models/img-20-01.jpg",
      imagePath: [
        "../images/models/img-20-01.jpg",
        "../images/models/img-20-02.jpg",
        "../images/models/img-20-03.jpg",
        "../images/models/img-20-04.jpg",
        "../images/models/img-20-05.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-20-05.png",
    },
    {
      id: 21,
      title: "Royal anarkali full sleeves gown",
      material: "",
      price: 7999,
      discountPrice: 4999,
      color: "#303538",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS021",
      description:
        "A long flared gown with full sleeves & plain dupatta for your cocktail parties & festive wear",
      image: "../images/models/img-21-01.jpg",
      imagePath: ["../images/models/img-21-01.jpg"],
    },
    {
      id: 22,
      title: "Ghagra choli set with blazer",
      material:
        "Printed silk lehenga & jacket with stone works & matching plain top",
      price: 7899,
      discountPrice: 6999,
      color: "#f8ccad",
      fabric: "silk",
      size: "Large",
      design: "",
      sku: "MS022",
      description: "",
      image: "../images/models/img-22-01.jpg",
      imagePath: ["../images/models/img-22-01.jpg"],
    },
    {
      id: 23,
      title: "Hot Pink peplum top with flared lehenga style palazzo",
      material:
        "",
      price: 8999,
      discountPrice: 6999,
      color: "#d11530",
      fabric: "Georgette",
      size: "Large",
      design: "",
      sku: "MS023",
      description: "Pink Embroidered peplum top with net dupatta & flared lehenga style palazzo set",
      image: "../images/models/img-23-01.jpg",
      imagePath: ["../images/models/img-23-01.jpg"],
    },
  ],
};

export const footerNavLinks = [
  {
    id: 1,
    webUrl: "/privacy-policy",
    title: "Privacy Policy",
  },
  {
    id: 2,
    webUrl: "/terms-&-conditions",
    title: "Terms & Conditions",
  },
  {
    id: 3,
    webUrl: "/exchange-policy",
    title: "Exchange Policy",
  },
];

export const sliderData = [
  {
    id: 1,
    image: "./images/banner/EthnicWebsiteBanner-01.jpg",
    altText: "Homepage Banner",
  },
  {
    id: 2,
    image: "./images/banner/EthnicWebsiteBanner-02.jpg",
    altText: "Homepage Banner",
  },
];

export const testimonials = [
  {
    name: "John Doe",
    text: "This product is amazing! Highly recommend it to everyone.",
    role: "CEO, Example Company",
    img: "",
  },
  {
    name: "Jane Smith",
    text: "Great customer service and top-notch quality!",
    role: "Marketing Head, Another Company",
    img: "",
  },
  {
    name: "Michael Johnson",
    text: "I had an awesome experience with this brand.",
    role: "Developer, Tech Corp",
    img: "",
  },
];

export const exhibitionVideos = [
  {
    id: 1,
    urlVideo: "../images/exhibition-videos/exhibition-01.mp4",
  },
  {
    id: 2,
    urlVideo: "../images/exhibition-videos/exhibition-02.mp4",
  },
  {
    id: 3,
    urlVideo: "../images/exhibition-videos/exhibition-03.mp4",
  },
];
